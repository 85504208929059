import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import headerlogo from "./images/Les_Logo.png";
import headerlogo from "./images/leslogo.svg";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [mbActive, setMbActive] = useState(false);
  const [currentPath, setCurrentPath] = useState("/");

  useEffect(() => {
    let url = window.location.pathname;
    setCurrentPath(url);
  }, [location]);


  return (
    <header>
      <div
        onClick={() => setMbActive(!mbActive)}
        className={`${mbActive && "active mobile-menu-overlay"}`}
      ></div>
      <div className="container">
        <div className="header_main_wrp">
          <div className="header_logo">
            {/* <img src="./assets/img/Les_Logo.png" alt="Logo" /> */}
            <Link to={"/"}>
              <img src={headerlogo} alt="Logo" />
            </Link>
          </div>
          <nav>
            <ul className={`${mbActive && "active"}`}>
              <li
                onClick={() => {setMbActive(!mbActive);setCurrentPath("/")}}
                className={`${currentPath === "/" && "active"}`}
              >
                <Link to="./">Home</Link>
              </li>
              <li
                className={`${currentPath === "/about" && "active"}`}
                onClick={() => {
                  setMbActive(!mbActive);
                  setCurrentPath("/about");
                }}
              >
                <Link to="/about">About</Link>
              </li>
              <li
                className={`${currentPath === "/features" && "active"}`}
                onClick={() => {
                  setMbActive(!mbActive);
                  setCurrentPath("/features");
                }}
              >
                <Link to="/features">Features</Link>
              </li>
              <li
                className={`${currentPath === "/portfolio" && "active"}`}
                onClick={() => {
                  setMbActive(!mbActive);
                  setCurrentPath("/portfolio");
                }}
              >
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li
                className={`${currentPath === "/pricing" && "active"}`}
                onClick={() => {
                  setMbActive(!mbActive);
                  setCurrentPath("/pricing");
                }}
              >
                <Link to="/pricing">Pricing</Link>
              </li>
              <li
                className={`${currentPath === "/contact" && "active"}`}
                onClick={() => {
                  setMbActive(!mbActive);
                  setCurrentPath("/contact");
                }}
              >
                <Link to="/contact">Contact</Link>
              </li>
              {/* <li onClick={() => setMbActive(!mbActive)}>
                <Link href="#" className="header-moblie-login">
                  Login
                </Link>
              </li> */}
            </ul>
            <div className="header_btn">
              {/* <Link href="">Login</Link> */}
              <button
                onClick={() => navigate("signup")}
                className="cmn-btn play-reel-btn"
              >
                <span className="cmn-btn-text1">Get Started</span>
                <span className="cmn-btn-text2">Get Started</span>
              </button>
              <button
                className="moblie-menu-btn"
                onClick={() => {
                  setMbActive(!mbActive);
                }}
              >
                <svg width="100" height="100" viewBox="0 0 100 100">
                  <path
                    className={`${mbActive && "active"} line line1`}
                    d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                  ></path>
                  <path
                    className={`${mbActive && "active"} line line2`}
                    d="M 20,50 H 80"
                  ></path>
                  <path
                    className={`${mbActive && "active"} line line3`}
                    d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                  ></path>
                </svg>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
