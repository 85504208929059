import React from "react";
import { Link } from "react-router-dom";
// import footerlogo from "./images/footer-logo.png";
import footerlogo from "./images/lesfooterlogo.svg";

function Footer() {
  return (
    <footer className="p-100 pb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="footer-logo">
              <img src={footerlogo} alt="logo" />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="footer-wrp">
              <h3>Company</h3>
              <ul>
                <li>
                  <Link to="about">What is Launchestore?</Link>
                </li>
                <li>
                  <Link to="features">Ecommerce Features</Link>
                </li>
                <li>
                  <Link to="pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="faq">FAQs</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="footer-wrp">
              <h3>Our Grocery App</h3>
              <ul>
                <li>
                  <Link href="">Grocery Ecommerce App</Link>
                </li>
                <li>
                  <Link href="">Manager Application</Link>
                </li>
                <li>
                  <Link href="">Delivery Boy Application</Link>
                </li>
                <li>
                  <Link href="">Admin Panel Features</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="footer-wrp">
              <h3>Quick Links</h3>
              <ul>
                <li>
                  <Link to="/zumkha-detail">Zumkha</Link>
                </li>
                <li>
                  <Link to="/kishori-detail">Kishoricreation</Link>
                </li>
                <li>
                  <Link to="/Unibest-Detail">Unibest</Link>
                </li>
                <li>
                  <Link to="/dpdecor-detail">DP Decor</Link>
                </li>
                <li>
                  <Link to="/YashIndustries_Detail">Yash Industries</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12">
            <div className="copyright-wrp">
              <div className="copyright-left">
                <ul>
                  <li>
                    <Link href="">Terms & Condition</Link>
                  </li>
                  <li>
                    <Link href="">Privacy & Policy</Link>
                  </li>
                  <li>
                    <Link href="">Security</Link>
                  </li>
                </ul>
              </div>
              <div className="copyright-center">
                <ul>
                  <li>
                    <Link to="https://x.com/launchestore?lang=en" className="twitter-icon">
                      <i className="fa-brands fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/launchestore/" className="facebook-icon">
                      <i className="fa-brands fa-facebook-f"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.instagram.com/launchestore/" className="instagram-icon">
                      <i className="fa-brands fa-instagram"></i>
                    </Link>
                  </li>
                  {/* <li>
                    <Link href="" className="github-icon">
                      <i className="fa-brands fa-github"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className="copyright-right">
                <p>All the rights reserved by LauncheStore</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
